import React, { useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Box, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import useWindowScroll from '@react-hook/window-scroll';

import Banner from '$landing-page/components/banner';
import Button from '$landing-page/components/button';
import PageSection from '$landing-page/components/page-section';

import HamburgerMenuDialog from './hamburger-menu-dialog';
import Navigation from './navigation';
import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const query = graphql`
    query pageHeader {
        site {
            siteMetadata {
                showBanner
            }
        }
        logo: file(relativePath: { eq: "logotype.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 40, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const PageHeader = (props) => {
    const { homePage } = props;

    const queryData = useStaticQuery(query);
    const classes = useStyles();
    const theme = useTheme();
    const isScreenSizeMd = useMediaQuery(theme.breakpoints.up('md'));
    const scrollY = useWindowScroll(60);

    const [bannerHeight, setBannerHeight] = useState(0);
    const [menuOpened, setMenuOpened] = useState(false);
    const [isMenuSticky, setIsMenuSticky] = useState(false);

    const { showBanner } = queryData.site.siteMetadata;

    const toggleMenu = () => {
        setMenuOpened((menuState) => !menuState);
    };

    useLayoutEffect(() => {
        if (scrollY > bannerHeight && !isMenuSticky) {
            setIsMenuSticky(true);
        } else if (scrollY <= bannerHeight && isMenuSticky) {
            setIsMenuSticky(false);
        }
    }, [isMenuSticky, scrollY, isScreenSizeMd, bannerHeight, showBanner]);

    return (
        <>
            <Banner onHeightChange={setBannerHeight} />
            <Box className={classes.root}>
                <Box className={classes.spaceMock} style={{ height: isMenuSticky ? 92 : 0 }} />
                <PageSection
                    className={clsx(classes.headerWrapper, {
                        [classes.headerWrapperSticky]: isMenuSticky,
                        [classes.headerWrapperStickyNarrow]: isMenuSticky && (!showBanner || scrollY > bannerHeight + 100),
                    })}
                >
                    <Grid container spacing={2} justify="space-between" alignItems="center">
                        <Grid item className={classes.leftOrRightGridItem}>
                            <Box
                                className={clsx({
                                    [classes.logoWrapperSticky]: isMenuSticky,
                                    [classes.logoWrapper]: !isMenuSticky,
                                })}
                                component={homePage ? 'h1' : 'div'}
                                margin="0"
                                fontSize="0"
                            >
                                <Link to="/">
                                    <GatsbyImage
                                        image={queryData.logo.childImageSharp.gatsbyImageData}
                                        alt="Avicenna Research"
                                        loading="eager"
                                    />
                                </Link>
                            </Box>
                        </Grid>
                        {isScreenSizeMd && (
                            <>
                                <Grid item xs>
                                    <Navigation />
                                </Grid>
                                <Grid item className={classes.leftOrRightGridItem}>
                                    <Grid item>
                                        <Button component="a" href="/app/dashboard" variant="contained">
                                            Sign In
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {!isScreenSizeMd && (
                            <>
                                <Grid item>
                                    <Box component="div">
                                        <IconButton onClick={toggleMenu} edge="end">
                                            <FontAwesomeIcon className={classes.menuIcon} icon={['fad', 'bars']} fixedWidth />
                                        </IconButton>
                                    </Box>
                                    <HamburgerMenuDialog open={menuOpened} onClose={toggleMenu} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </PageSection>
            </Box>
        </>
    );
};

PageHeader.defaultProps = {
    homePage: false,
};

PageHeader.propTypes = {
    homePage: PropTypes.bool,
};

export default PageHeader;
